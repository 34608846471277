import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Container, Button } from '@mui/material';

const PortContent = props => {
    const project = props.project;
    return (
        <Container sx={{padding: '3rem 0rem 25vh 0rem'}}>
            <Grid container spacing={2} p={2}>
                <Grid item xs={12} md={12} rowSpacing={6}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <h1 style={{
                            marginRight: 'auto'
                        }} >{project.name}</h1>
                        <Button
                            variant='text'
                            onClick={props.goback}
                        >Back</Button>
                    </Box>
                    <br/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={project.image[0].url} alt={project.name} style={{width: `100%`, padding: '0rem 1rem'}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <h3>Description:</h3>
                    <div dangerouslySetInnerHTML={{ __html: project.description }} ></div>
                    <br />
                    <h3>Technologies:</h3>
                    <div className="tags-container">
                        {project.tags.map(tag => 
                            <span key={tag} >{ tag }</span>
                        )}
                    </div>
                    <br />
                    <Box>
                        <Button variant='outlined' href={project.demo} target="_blank" sx={{ marginRight: '6px' }}>Live Demo</Button>
                        {
                            project.sourceCode &&
                            <Button variant='outlined' href={project.sourceCode} target="_blank" sx={{marginRight: '6px'}}>Source Code</Button>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default PortContent;