import './App.css';
import './AppRes.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getTheme from './Theme/GetTheme';
import Header from './Components/Header';
import Portfolio from './Components/Portfolio';
import Footer from './Components/Footer';
import About from './Components/About';
import Ene from './Components/Ene';
import Contact from './Components/Contact';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";


const theme = createTheme(getTheme());

function App() {
  return (
    
  <ThemeProvider theme={theme}>
    <div className='App'>
      <Router>
        <Header />
        <Routes>
            <Route path={`/about`} element={<About />} />
            <Route path={`/ene`} element={<Ene />} />
            <Route path={`/contact`} element={<Contact/>} />
            <Route path={`/portfolio`} element={<Portfolio />} />
            <Route path={`/`} element={<Navigate replace to={`/portfolio`}/>}  />
        </Routes>
        <Footer />
      </Router>
    </div>
  </ThemeProvider>
    
  );
}

export default App;
