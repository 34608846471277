import { Fragment, useState, useEffect } from "react"
import {
    useTransition,
    useSpring,
    useChain,
    config,
    animated,
    useSpringRef,
} from '@react-spring/web'
import PortContent from "./PortContent";

const PortItem = props => {

    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const project = props.project;
    const springApi = useSpringRef();
    const { width, height, top, left , ...rest } = useSpring({
        ref: springApi,
        config: config.stiff,
        from: {
            width: `0vw`,
            height: `0vh`,
            top: `0px`,
            left: `0px`
        },
        to: {
            width: open ? `100vw` : `0vw`,
            height: open ? `100vh` : `0vh`,
            top: open ? `0vh` : `50vh`,
            left: open ? `0vw` : `50vw`,
        }
    })

    useChain(open ? [springApi] : [springApi], [
        0,
        open ? 0.1 : 0.6,
    ])

    useEffect(() => {
        const changeScrollFocus = (open) => {
            // console.log(open);
            if (open) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }
        }
        changeScrollFocus(open);
    }, [open]);
    


    return (
        <Fragment>
            <div
                className={`item${loaded ? ` item-loaded` : ``}`}
                style={{
                    cursor: !open ? `pointer` : `default`
                }}
                onClick={!open ? () => setOpen( open => !open ) : () => {}}
            >
                <img
                    className="cover-image-holder"
                    src={project.image[0].url}
                    alt={props.content}
                />
                <img
                    className="cover-image"
                    src={project.image[0].url}
                    alt={props.content}
                    onLoad={(e) => {
                        e.target.style.opacity = '1';
                        setLoaded(true);
                    }}
                />
                <div className="overlay">
                    <div className="inner">
                        <h2>{project.name}</h2>
                        <div className="tags-container">
                            {project.tags.map(tag => 
                                <span key={tag} >{ tag }</span>
                            )}
                        </div>
                    </div>
                </div>
                <animated.div
                    className={`content`}
                        style={{
                            ...rest,
                            width: width,
                            height: height,
                            top: top,
                            left: left
                        }}
                >
                    <PortContent project={project} goback={() => setOpen(false)} />
                </animated.div>
            </div>
        </Fragment>
    )
}

export default PortItem;