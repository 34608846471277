import { Container } from '@mui/material';
import Box from '@mui/material/Box';


const Footer = () => {
    return (
        <Container>
            <Box sx={{padding: '2rem'}}>ⒸBEN.TSANG HL</Box>
        </Container>
    )
}

export default Footer;