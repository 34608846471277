import { Container } from "@mui/material";


const Contact = props => {
    return (
        <Container>
            
        </Container>
    )
}

export default Contact;