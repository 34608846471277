import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import PortItem from './PortfolioItem';
import Masonry from 'react-masonry-css';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { request } from 'graphql-request';

const Portfolio = props => {
    const num = 10;

    const [portFilter, setPortFilter] = useState(`none`);
    const [projectData, setProjectData] = useState({
        projects: [],
        loaded: false
    })

    useEffect(() => {
        const loadData = async () => {
            const { projects } = await request(
                process.env.REACT_APP_GRAPHCMS_API,
                `{
                    projects(orderBy: weight_DESC) {
                        demo
                        description
                        id
                        name
                        tags
                        sourceCode
                        weight
                        image {
                            url
                            size
                        }
                    }
                }`
            )
            setProjectData({
                projects,
                loaded: true
            })
            return
        }
        loadData();
    }, []);

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };
    

    return (
        <>
            <Container>
                {/* <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: `1rem`,
                        alignItems: 'center'
                    }}
                    className="filter-container"
                >
                    <Typography variant='p' sx={{marginRight: 'auto'}}></Typography>
                    <Typography variant='p'
                        sx={{
                            padding: `1rem`
                        }}
                    >Stack Filters: </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        value={portFilter}
                        exclusive
                        onChange={(e, v) => { setPortFilter(v) }}
                        size={`small`}
                        sx={{
                            padding: `1rem 0rem`
                        }}
                    >
                        <ToggleButton value="none">None</ToggleButton>
                        <ToggleButton value="react">React Js</ToggleButton>
                        <ToggleButton value="villain">Villain Js</ToggleButton>
                        <ToggleButton value="ionic">Ionic</ToggleButton>
                    </ToggleButtonGroup>
                </Box> */}
                {
                    projectData.loaded &&
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="portfolio"
                        columnClassName="portfolio_column"
                    >
                        {
                            projectData.projects.map((project, index) => 
                                <PortItem key={`PortItem-${project.id}`} project={project} />
                            )
                        }
                    </Masonry>
                }
            </Container>
        </>
    )
}

export default Portfolio;