import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Button, Container } from '@mui/material';
import { ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import {IoLogoGitlab, IoLogoFacebook, IoLogoLinkedin, IoLogoGoogle, IoCall, IoDownloadOutline } from "react-icons/io5"
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';


const pages = [
    {
        name: 'Portfolio',
        path: 'portfolio'
    },
    {
        name: 'About Me',
        path: 'about'
    },
    {
        name: 'Experiences & Educations',
        path: 'ene'
    },
    // {
    //     name: 'Resources',
    //     path: 'resources'
    // },
    // {
    //     name: 'Contact',
    //     path: 'contact'
    // },
];

const Header = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Container>
            <AppBar position="static" color='transparent' elevation={0}>
                <Toolbar>
                    <IconButton
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => setMenuOpen(true)}
                    >
                    <MenuIcon />
                    </IconButton>
                    <Button
                        onClick={() => {
                            navigate(`/`);
                        }}
                    >
                    <Typography variant="h4" component="div" sx={{ fontFamily:`'Poppins', sans-serif`, fontSize: '100%', textAlign: 'center', padding: '5vh 0rem', color: '#333' }}>
                        <b>BEN TSANG</b>{`|PORTFOLIO`}
                    </Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                onOpen={() => console.log('opened')}
                elevation={1}
            >
                <List sx={{
                    padding: '3rem 0rem'
                }}>
                    {
                        pages.map((page, index) => 
                            <ListItem key={`menu-item-${index}`}>
                                <ListItemButton
                                    onClick={() => {
                                        navigate(`/${page.path}`);
                                        setMenuOpen(false)
                                    }}
                                >
                                    {page.name}
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                    <div style={{ padding: '1rem 0rem' }}></div>
                    <Divider />
                    <div style={{ padding: '1rem 0rem' }}></div>
                    <ListSubheader>Resources</ListSubheader>
                    <ListItem>
                        <ListItemButton
                            component="a"
                            href={`mailto:ben.tsanghoilun@gmail.com`}
                            target={`_blank`}
                        >
                            <ListItemIcon>
                                <IoLogoGoogle/>
                            </ListItemIcon>
                            <ListItemText>ben.tsanghoilun</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            component="a"
                            href={`tel:+447888002828`}
                            target={`_blank`}
                        >
                            <ListItemIcon>
                                <IoCall/>
                            </ListItemIcon>
                            <ListItemText>+44 07888002828</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            component="a"
                            href={`https://www.facebook.com/ben.tsanghoilun`}
                            target={`_blank`}
                        >
                            <ListItemIcon>
                                <IoLogoFacebook/>
                            </ListItemIcon>
                            <ListItemText>ben.tsanghoilun</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            component="a"
                            href={`https://www.linkedin.com/in/ben-tsang-85323531/`}
                            target={`_blank`}
                        >
                            <ListItemIcon>
                                <IoLogoLinkedin/>
                            </ListItemIcon>
                            <ListItemText>ben-tsang-85323531</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            component="a"
                            href={`https://gitlab.com/bentsanghoilun`}
                            target={`_blank`}
                        >
                            <ListItemIcon>
                                <IoLogoGitlab/>
                            </ListItemIcon>
                            <ListItemText>ben-tsanghoilun</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            component="a"
                            href={`https://drive.google.com/file/d/1XtRECZBCdy4psyPHDuMVLgDg_HIywYVw/view?usp=sharing`}
                            target={`_blank`}
                        >
                            <ListItemIcon>
                                <IoDownloadOutline/>
                            </ListItemIcon>
                            <ListItemText>Download Resume</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </SwipeableDrawer>
        </Container>
    )
}

export default Header;