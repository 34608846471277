import { Container } from "@mui/material";
import dayjs from 'dayjs';
import { request } from 'graphql-request';
import { useEffect, useState } from 'react';
import DayJS from 'react-dayjs';
import { Fade } from "react-awesome-reveal";


const Ene = props => {

    const [eneData, setEneData] = useState({
        loaded: false
    })

    useEffect(() => {
        const loadData = async () => {
            const { experiences, educations } = await request(
                process.env.REACT_APP_GRAPHCMS_API,
                `{
                    experiences(orderBy: period_DESC) {
                        description
                        jobTitle
                        id
                        organisation
                        organisationUrl
                        period
                        technology
                    }
                    educations {
                        graduationDate
                        id
                        institution
                        qualification
                    }
                }`
            )
            console.log(experiences);
            setEneData({
                experiences,
                educations,
                loaded: true
            })
            return
        }
        loadData();
    }, []);


    return (
        <Container>
            <Fade bottom cascade>
                <div>
                    <h1 style={{ marginBottom: '2rem' }} >Experiences & Educations</h1>
                    <Fade bottom cascade>
                        <div className="custom-paper">
                        
                            <h2 style={{marginBottom: '2rem'}}>Experiences</h2>
                            {
                                eneData.loaded && eneData.experiences.map((exp, index) => 
                                    <div key={`exe-item-${exp.id}`} className={`exp-item`}>
                                        <h4>{exp.jobTitle}</h4>
                                        <div>{exp.organisation} | <DayJS format={`MMM YYYY`}>{exp.period[0]}</DayJS> - { 
                                            dayjs().isAfter(dayjs(exp.period[1])) ? <DayJS format={`MMM YYYY`}>{exp.period[1]}</DayJS> : 'present'
                                        }</div>
                                        <br />
                                        <p style={{fontFamily: 'Poppins', color:'#aaa'}} >{exp.description}</p>
                                    </div>
                                )
                            }
                        
                        </div>
                    </Fade>
                    <div className="custom-paper">
                        <h2 style={{marginBottom: '2rem'}}>Educations</h2>
                        {
                            eneData.loaded && eneData.educations.map((edu, index) =>
                                <div key={`exe-item-${edu.id}`} className={`exp-item`}>
                                    <h3>{edu.qualification}</h3>
                                    <div>{edu.institution} | <DayJS format={`MMM YYYY`}>{edu.graduationDate}</DayJS></div>
                                    <br />
                                </div>
                            )
                        }
                    </div>
                </div>
            </Fade>
        </Container>
    )
}

export default Ene;