const getTheme = () => {
    return {
        palette: {
            
        },
        typography: {
            fontFamily: [
                'IBM Plex Mono',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    }
}

export default getTheme;