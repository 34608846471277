import { Box, Container, Grid, Typography } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import { request } from 'graphql-request';
import { Fragment, useEffect, useState } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';


const About = props => {

    const [data, setData] = useState({
        loaded: false
    })

    const summary = [
        {
            years: 10,
            of: 'Coding'
        },
        {
            years: 5,
            of: 'React & Redux'
        },
        {
            years: 3,
            of: 'Firebase'
        },
        {
            years: 2,
            of: 'Ionic'
        },
        {
            years: 2,
            of: 'Laravel'
        },
    ];

    useEffect(() => {
        const loadData = async () => {
            const { author, cores } = await request(
                process.env.REACT_APP_GRAPHCMS_API,
                `{
                    author(where: {slug: "ben-tsang"}) {
                        bio
                        id
                        intro
                        name
                        picture {
                          size
                          url
                        }
                        stacksAndTechnologies
                    },
                    cores{
                        id
                        order
                        content
                    }
                }
                `
            )
            console.log(author);
            console.log(cores);
            setData({
                author,
                cores,
                loaded: true
            })
            return
        }
        loadData();
    }, [])

    return (
        <Container>
            {
                !data.loaded ?
                    <Fragment>
                        <Box>
                            <Skeleton variant="rectangular" width={400} height={80} />
                            <br/>
                        </Box>
                        <Box>
                            <Skeleton variant="rectangular" height={120} />
                            <br/>
                            <Skeleton variant="rectangular" width={200} />
                            <br/>
                            <Skeleton variant="rectangular" width={200}/>
                        </Box>
                        <br/>
                        <Box>
                            <Skeleton variant="rectangular" height={120} />
                            <br/>
                            <Skeleton variant="rectangular" width={200} />
                            <br/>
                            <Skeleton variant="rectangular" width={200}/>
                        </Box>
                    </Fragment>
                    :
                    <div>
                            <h1 style={{ marginBottom: '2rem' }} >About Me</h1>
                            <Fade
                                cascade={true}
                                direction='up'
                            >
                                <div className="custom-paper">
                                    <Grid container>
                                        <Grid item md={3} xs={12} sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: '2rem'
                                        }}>
                                            <Avatar
                                                alt="Ben Tsang"
                                                src={data.author.picture.url}
                                                sx={{
                                                    width: 96,
                                                    height: 96,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={8} xs={12} sx={{paddingBottom: 3}}>
                                            <h2 style={{marginBottom: '1rem'}}>Bios</h2>
                                            <div dangerouslySetInnerHTML={{ __html: data.author.bio }}></div>
                                        </Grid>
                                    </Grid>
                                </div>
                        </Fade>
                        <Fade
                            cascade
                            direction='up'
                        >
                            <div className="custom-paper">
                                <h2 style={{ marginBottom: '2rem' }}>Cores</h2>
                                <ul>
                                    {
                                        data.cores.sort((a, b) => a.order - b.order).map(x => (
                                            <li key={x.id} className={`core`}>
                                                {x.content}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Fade>
                        
                            <div className="custom-paper">
                                <h2 style={{ marginBottom: '2rem' }}>Summary</h2>
                                <div className="summary-container">
                                    
                                        {
                                            summary.map((item, index) => 
                                                <Zoom bottom cascade key={index}>
                                                    <div className="item-wrapper" style={{
                                                        // display: 'flex',
                                                        // flexDirection: 'column',
                                                        // alignItems: 'center',
                                                        // justifyContent: 'center',
                                                        // maxWidth: '25%'
                                                    }}>
                                                        <Avatar variant="rounded" sx={{
                                                            width: 64 + (item.years / 10 * 50),
                                                            height: 64 + (item.years / 10 * 50),
                                                            fontSize: 38 + (item.years / 10 * 30),
                                                            fontFamily: 'Poppins, sans-serif',
                                                        }}>{ item.years}</Avatar>
                                                        <Typography sx={{ margin: 2 }}>{ `Year${item.years <= 1 ? '':'s'} of ${item.of}` }</Typography>
                                                    </div>
                                                </Zoom>
                                            )
                                        }
                                        
                                    
                                </div>
                            </div>
                            <div className="custom-paper">
                                <h2 style={{marginBottom: '2rem'}}>Stacks & Technologies</h2>
                                {
                                    data.author.stacksAndTechnologies.map((item, index) =>
                                        <Chip
                                            key={index}
                                            label={item}
                                            sx={{
                                                marginRight: 2,
                                                marginBottom: 1
                                            }}
                                        />
                                    )
                                }
                            </div>
                        </div>
            }
        </Container>
    )
}

export default About;